@font-face {
  font-family: 'ilsa-icons';
  src: url('../fonts/ilsa-icons.eot?sx8yyj');
  src: url('../fonts/ilsa-icons.eot?sx8yyj#iefix') format('embedded-opentype'), url('../fonts/ilsa-icons.ttf?sx8yyj') format('truetype'), url('../fonts/ilsa-icons.woff?sx8yyj') format('woff'), url('../fonts/ilsa-icons.svg?sx8yyj#ilsa-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ilsa-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-empty-car:before {
  content: "\e900";
}
.icon-refresh:before {
  content: "\e905";
}
.icon-checkbox:before {
  content: "\e972";
}
.icon-empty-marker:before {
  content: "\e973";
}
.icon-marker:before {
  content: "\e974";
}
.icon-bookmark:before {
  content: "\e975";
}
.icon-call-phone:before {
  content: "\e976";
}
.icon-navigation-arrow:before {
  content: "\e977";
}
.icon-close-bold:before {
  content: "\e978";
}
.icon-close:before {
  content: "\e979";
}
.icon-down:before {
  content: "\e97a";
}
.icon-error:before {
  content: "\e97b";
}
.icon-filter-default:before {
  content: "\e97c";
}
.icon-filter-history:before {
  content: "\e97d";
}
.icon-next-small:before {
  content: "\e97e";
}
.icon-clear:before {
  content: "\e97f";
}
.icon-list:before {
  content: "\e980";
}
.icon-pencil-edit:before {
  content: "\e981";
}
.icon-phone:before {
  content: "\e982";
}
.icon-price:before {
  content: "\e983";
}
.icon-left-arrow:before {
  content: "\e984";
}
.icon-next:before {
  content: "\e985";
}
.icon-search:before {
  content: "\e986";
}
.icon-success:before {
  content: "\e987";
}
.icon-trash-can:before {
  content: "\e988";
}
.icon-map:before {
  content: "\e989";
}
.icon-add-to-list:before {
  content: "\e98a";
}
.icon-compare:before {
  content: "\e98b";
}
