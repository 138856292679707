.margin-left-0 {
  margin-left: 0 !important;
}
.popup {
  position: fixed;
  z-index: 3000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.popup.active {
  display: flex;
}
.popup .overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup .s-popup-content {
  width: 20.9375rem;
  margin-left: -10.4375rem;
  font-weight: normal;
  border: 0.0625rem solid #f2f2f2;
  align-self: center;
  position: absolute;
  border-radius: 0;
  left: 50%;
  top: 50%;
  transform: translateY(-50%);
  background: #ffffff;
  z-index: 99999;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  font-style: normal;
  color: #37474f;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.popup .s-popup-content .s-wrapper {
  padding: 1.25rem;
}
.popup .s-popup-content .s-wrapper .icon-button {
  position: absolute;
  top: 12px;
  right: 12px;
}
.popup .s-popup-content .s-wrapper .icon-button .icon-close-bold {
  color: #a3a3a3;
  font-size: 18px;
}
.popup .s-popup-content .s-wrapper .icon-button .icon-close-bold::before {
  position: relative;
  top: -1px;
}
.popup .s-popup-content .s-wrapper .loading_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 343px;
}
.popup .s-popup-content .s-wrapper .loading_container img {
  width: 100px;
  height: 100px;
}
.popup .s-popup-content.colors {
  width: 18.75rem;
  margin-left: -9.375rem;
}
.popup .s-popup-content .s-close {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
}
.popup .s-popup-content .s-close img {
  width: 18px;
  height: 18px;
}
.popup .s-popup-content .s-text {
  margin-bottom: 0.1875rem;
}
.popup .s-popup-content .s-text .s-title {
  font-weight: bold;
  font-size: 1rem;
  color: #37474f;
  padding-right: 1.25rem;
  text-align: left;
}
.popup .s-popup-content .s-text .s-title.error {
  padding-right: 0;
  text-align: center;
}
.popup .s-popup-content .s-text .s-title.success {
  padding-right: 0;
  text-align: center;
}
.popup .s-popup-content .s-text .s-error,
.popup .s-popup-content .s-text .s-success {
  text-align: center;
  margin: 8px 0 14px;
}
.popup .s-popup-content .s-text .s-error .icon-error,
.popup .s-popup-content .s-text .s-success .icon-error {
  font-size: 170px;
  color: #f10404;
}
.popup .s-popup-content .s-text .s-error .icon-success,
.popup .s-popup-content .s-text .s-success .icon-success {
  font-size: 170px;
  color: #ffab00;
}
.popup .s-popup-content .s-text .s-description {
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
}
@media (max-width: 767px) {
  .popup .s-popup-content.form {
    width: 23.92857143rem;
    margin-left: -11.92857143rem;
  }
  .popup .s-popup-content .s-wrapper {
    padding: 0;
  }
  .popup .s-popup-content .s-wrapper .s-close.colors {
    display: none;
  }
  .popup .s-popup-content.colors {
    width: 21.42857143rem;
    margin-left: -10.71428571rem;
  }
  .popup .s-popup-content .s-text {
    padding: 1.42857143rem 1.42857143rem 0 1.42857143rem;
    margin-bottom: 0;
  }
  .popup .s-popup-content .s-text .s-description {
    margin: 0.71428571rem 0;
  }
}
